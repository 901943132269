import React, {useState,useEffect} from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import axios from 'axios'

import Cookies from 'js-cookie';

const HomePolls = () => {
    const {t} = useTranslation()

    

    const [data, setData] = useState([]); // Poll data
    const [selectedOptions, setSelectedOptions] = useState({}); // Temporary selection
    const [submittedPolls, setSubmittedPolls] = useState({});


    const backUrl = process.env.REACT_APP_BACK_URL


    useEffect(() => {
        // Fetch poll data
        axios.get(`${backUrl}/admin/getpolls`).then((res) => {
            setData(res.data.reverse()); // Reverse the data if required
        });

        // Load submitted polls from cookies
        const savedSubmittedPolls = Cookies.get("submittedPolls");
        if (savedSubmittedPolls) {
            setSubmittedPolls(JSON.parse(savedSubmittedPolls));
        }

        // const submittedOption = submittedPolls[_id]

    }, []);

    useEffect(() => {
        axios.get(`${backUrl}/admin/getpolls`)
            .then((res) => {
                if (Array.isArray(res.data)) {
                    setData(res.data.reverse());

                } else {
                    setData([]);
                }

                // Load submitted polls from cookies
                const savedSubmittedPolls = Cookies.get("submittedPolls");
                if (savedSubmittedPolls) {
                    setSubmittedPolls(JSON.parse(savedSubmittedPolls));
                }
            })
            .catch((err) => console.error("Error fetching polls:", err));
    }, []);








    const handleSubmit = (_id) => {


        const selectedOption = selectedOptions[_id];

        // const optionKey = data.find((poll) => poll._id === _id)?.option1 === selectedOption
        //     ? "option2"
        //     : "option1";


        axios.post(`${backUrl}/admin/submitpoll`, {
            pollId: _id,
            selectedOption
        }).then((res) => {
            alert("Your vote has been submitted!");
            console.log(res, "psubbmit")
            setSubmittedPolls((prev) => {
                const updatedSubmittedPolls = { ...prev, [_id]: selectedOption };
                Cookies.set("submittedPolls", JSON.stringify(updatedSubmittedPolls)); 
                // Save to cookies
                return updatedSubmittedPolls;
            });

            // Clear temporary selection for this poll
            setSelectedOptions((prev) => {
                const updatedSelections = { ...prev };
                delete updatedSelections[_id];
                return updatedSelections;
            });

            // setData(res.data.polls);
            axios.get(`${backUrl}/admin/getpolls`).then((res) => {
                setData(res.data.reverse()); // Reverse the data if required
            });
    


        }).catch((err) => {
            console.error("Error submitting poll:", err);
            alert("Failed to submit your vote. Please try again.");
        });



    };
    return (
        <div>  <div className='rounded-xl text-blue-950 border dark:border-none dark:text-white dark:bg-gray-800 bg-white px-3 py-3'>
            <div class="text-2xl  font-medium  border-b pb-3">{t("Latest Polls")}</div>


            <div className=' mb-4 dark:bg-gray-800 dark:border-none  w-full'>
                    {data.length > 0 ?
                        <div class="flex flex-col md:flex-row p-6 space-y-4 md:space-y-0 md:space-x-4 w-full md:overflow-x-scroll scrollbar-hide custom-scrollbar">

                            <div className='md:flex md:flex-shrink-0 md:gap-4  custom-scrollbar scrollbar-hide'>
                                {data?.slice(0, Math.min(data?.length, 5)).map((item) => {
                                    // Check if the poll is already submitted
                                    const submittedOption = submittedPolls[item._id];

                                    return (
                                        <div key={item._id} className="poll-container bg-reghd-700 md:w-72 md:h- my-4 p-4 border rounded-lg">
                                            <p className="text-lg font-semibold">{item.question}</p>

                                            {submittedOption ? (
                                                <div className="submitted-poll-message mt-2 text-green-600">
                                                    <p>You have already submitted this poll.</p>
                                                    <p>Your selected option: <strong>{item[submittedOption]}</strong></p>

                                                    <div className="flex font-medium justify-around mt-4">
                                                        {/* Option 1 */}
                                                        <div className="flex flex-col items-center">
                                                            <p className="text-sm">{item.votes.option1}</p>
                                                            <div className="relative bg-gray-200 w-24 h-24 flex items-end overflow-hidden">
                                                                <div
                                                                    className="bg-blue-500 w-full"
                                                                    style={{
                                                                        height: `${(item.votes.option1 / (item.votes.option1 + item.votes.option2) || 1) * 100}%`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <p className="text-sm">{item.option1}</p>

                                                            {/* <p className="text-sm mt-2">{((item.votes.option1 / (item.votes.option1 + item.votes.option2) || 0) * 100).toFixed(1)}%</p> */}
                                                        </div>

                                                        {/* Option 2 */}
                                                        <div className="flex flex-col items-center">
                                                            <p className="text-sm">{item.votes.option2}</p>
                                                            <div className="relative bg-gray-200 w-24 h-24 flex items-end overflow-hidden">
                                                                <div
                                                                    className="bg-green-500 w-full"
                                                                    style={{
                                                                        height: `${(item.votes.option2 / (item.votes.option1 + item.votes.option2) || 1) * 100}%`,
                                                                    }}
                                                                ></div>
                                                            </div>
                                                            <p className="text-sm">{item.option2}</p>

                                                            {/* <p className="text-sm mt-2">{((item.votes.option2 / (item.votes.option1 + item.votes.option2) || 0) * 100).toFixed(1)}%</p> */}
                                                        </div>





                                                    </div><div className='font-medium'>
                                                        Total Votes: {(item.votes.option1 + item.votes.option2) || 1}
                                                    </div>


                                                </div>

                                            ) : (
                                                <div className="poll-options mt-2">
                                                    <div>
                                                        <input
                                                            id={`${item._id}_option1`}
                                                            type="radio"
                                                            name={`poll-${item._id}`}
                                                            onChange={() => setSelectedOptions({ ...selectedOptions, [item._id]: "option1" })}
                                                            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600"
                                                        />
                                                        <label htmlFor={`${item._id}_option1`} className="ml-2">
                                                            {item.option1}
                                                        </label>
                                                    </div>
                                                    <div className="mt-2">
                                                        <input
                                                            id={`${item._id}_option2`}
                                                            type="radio"
                                                            name={`poll-${item._id}`}
                                                            onChange={() => setSelectedOptions({ ...selectedOptions, [item._id]: "option2" })}
                                                            className="h-4 w-4 border-gray-300 bg-gray-100 text-blue-600"
                                                        />
                                                        <label htmlFor={`${item._id}_option2`} className="ml-2">
                                                            {item.option2}
                                                        </label>
                                                    </div>

                                                    <button
                                                        onClick={() => handleSubmit(item._id)}
                                                        className="mt-4 rounded-lg bg-blue-950 px-3 py-2 text-center text-sm font-medium text-white hover:bg-blue-800"
                                                    >
                                                        Submit
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>



                        </div>

                        : <p className='text-center text-sm mt-6 text-red-500 font-medium flex flex-col justify-center items-center gap-4 '><img className='size-32' src='/cricket-stump.png' />No Polls Available !</p>}







                </div>


          

            <div className=' text-center'>
                <Link to="polls" class=" mt-2 rounded-lg px-3 py-2 text-blue-950  text-sm font-medium hover:underline">
                    {t("See More")}
                </Link>


            </div>








        </div>
        </div>
    )
}

export default HomePolls